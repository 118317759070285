import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, merge } from 'rxjs/operators';
import { Globals } from '@foh/globals';
import { CustomerProfile } from './customerprofile';
import { getUnknownGuestObject } from "@foh/shared/services/utils";

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient, private globals : Globals) { }

   search(term: string) {
    if (term === '') {
      return of([]);
    }

    return this.http.get<any[]>(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_SEARCH, { params: {term:term} });
  }

  getAll(params){
      return this.http.get<any[]>(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_LIST,  { params: params });
  }

  get(id){
    if(id == "-1"){
      return this.getUnknownGuest();
    }
      return this.http.get<any>(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_GET, { params: {id:id} });
  }

  refreshStats(id){
      return this.http.get<any>(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_REFRESHSTATS, { params: {id:id} });
  }

  getUnknownGuest(){
    var customerObject = {
      activityLogs  : [],
      guests : [],
      hosts : [],
      tickets : [],
      customer : getUnknownGuestObject()
    };
    return of(customerObject);
  }

  getVisits(id){
      return this.http.get<any>(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_VISITS, { params: {id:id} });
  }

  getActivityLogs(id){
      return this.http.get<any>(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_ACTIVITYLOGS, { params: {id:id} });
  }

  addCustomer(params){
      return this.http.post<CustomerProfile>(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_CREATE, params);
  }

  addCustomerAndReplaceGuest(params){
      return this.http.post<CustomerProfile>(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_CREATE_W_REPLACE_GUEST, params);
  }
  update(params){
      return this.http.post<CustomerProfile>(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_UPDATE, params);
  }
  delete(id){
      return this.http.get(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_DELETE, { params: {id:id} });
  }

  changeMobileNumber(params){
      return this.http.post(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_CHANGEMOBILENUMBER, params);
  }

  getBirthdaysByDuration(params){
    return this.http.post(this.globals.WEBAPI + this.globals.URL_CUSTOMERS_BIRTHDAY_LIST, params);
  }
}
