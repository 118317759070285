import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SmsService } from "@foh/shared/services/smsservice.service";
import { LookupService } from "@foh/shared/services/lookup.service";
import { NotificationsService } from "@foh/shared/services/notifications.service";
import { CallmanagerService } from "@foh/shared/services/callmanager.service";
import { AudioService } from "@foh/shared/services/audio.service";
import { Subscription } from "rxjs";
import { PickCallComponent } from "@foh/shared/modules/shared-customers-component/pick-call/pick-call.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReservationupdaterService } from "@foh/shared/services/reservationupdater.service";
import { FohmoduleSelectorService } from "@foh/shared/services/fohmodule-selector.service";
import { SuperAdminBoardService } from "@foh/super-admin-board/super-admin-board.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  smsListener: Subscription;
  smsBalance: any;
  callManagerListener: Subscription;
  notificationsListener: Subscription;
  notifications: any = [];
  unReadNotifications: number = 0;
  mCompanyName: any;
  currentSelectedModule: string;
  selectedMCompanyId: string = "";
  mCompanies: any[] = [];
  loadingViewAs: boolean = false;
  constructor(
    private smsService: SmsService,
    private lookupService: LookupService,
    private notificationsService: NotificationsService,
    private callmanagerService: CallmanagerService,
    private modalService: NgbModal,
    private reservationupdaterService: ReservationupdaterService,
    private audioService: AudioService,
    private fohmoduleSelectorService: FohmoduleSelectorService,
    private authService: AuthService,
    private router: Router,
    private superAdminBoardService: SuperAdminBoardService
  ) { }

  ngOnInit() {
    this.bindSmsServiceEvents();
    this.bindCallManagerEvents();
    this.bindNotificationsEvents();
    this.smsBalance = this.lookupService.vars["smsBalance"];
    this.notifications = this.notificationsService.notifications;
    this.unReadNotifications = this.notificationsService.numberOfUnread;
    this.currentSelectedModule = this.fohmoduleSelectorService.getCurrentModule();

    if (this.authService.isSuperAdmin()) {
      this.superAdminBoardService.listAllMCompanies().subscribe(response => {
        this.mCompanies = response;
        const currentMCompany = this.lookupService.getMCompany();
        this.selectedMCompanyId = currentMCompany ? currentMCompany.id : null;
      });
    }
  }

  onCompanyChange() {
    const mCompanyId = this.selectedMCompanyId;
    this.loadingViewAs = true;
    this.authService.viewAsMCompany(mCompanyId).subscribe(
      response => {
        window.location.reload();
        this.authService.updateToken(response);
      },
      err => {
        this.loadingViewAs = false;
      }
    );
  }
  // ngAfterViewChecked() {
  //   setTimeout(() => {
  //     var wrapperDiv = document.getElementsByClassName("wrapper")[0];
  //     var dir = wrapperDiv.getAttribute("dir");
  //     if (dir === "rtl") {
  //       this.placement = "bottom-left";
  //     } else if (dir === "ltr") {
  //       this.placement = "bottom-right";
  //     }
  //   }, 3000);
  // }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else this.toggleClass = "ft-maximize";
  }

  logoutUser() {
    this.authService.logout();
    this.router.navigate(["login"]);
  }

  editUser() {
    if (this.authService.isSuperAdmin()) {
      this.router.navigate([
        "users-setup/superadmins/edit/" + this.authService.user.id
      ]);
    } else {
      this.router.navigate([
        "users-setup/users/edit/" + this.authService.user.id
      ]);
    }
  }

  viewMyActivityLog() {
    this.router.navigate([
      "users-setup/users/logs/" + this.authService.user.id
    ]);
  }

  bindSmsServiceEvents() {
    this.smsListener = this.smsService.listen().subscribe(event => {
      if (event.type == "sms-balance-updated") {
        this.smsBalance = event.newBalance;
      }
    });
  }

  bindCallManagerEvents() {
    this.callManagerListener = this.callmanagerService
      .listen()
      .subscribe(event => {
        if (event.type == "open-step1-modal") {
          this.openPickupCallModal(event.params);
        }
      });
  }

  bindNotificationsEvents() {
    this.notificationsListener = this.notificationsService
      .listen()
      .subscribe(event => {
        if (event.type == "unreadcounter-updated") {
          this.unReadNotifications = event.data.numberOfUnread;
        }
        if (event.type == "notifcation-added") {
          this.notifications = this.notificationsService.notifications;
          this.unReadNotifications = this.notificationsService.numberOfUnread;
          this.audioService.playNewNotificationSound();
        }
      });
  }

  openPickupCallModal(params) {
    const modalRef = this.modalService.open(PickCallComponent, { size: "lg" });
    modalRef.componentInstance.customerProfile = params.customerprofile;
    modalRef.componentInstance.mobileno = params.mobileno;
    modalRef.componentInstance.proceedEmitter.subscribe(response => {
      this.callmanagerService.saveCallParams(response);
      this.reservationupdaterService.broadcastIncomingCallReservation();
      modalRef.componentInstance.activeModal.dismiss("Saved");
      this.router.navigate(["rez/reservations/" + response.branch_id]);
    });
  }

  onNotificationOpen(event) {
    if (!event) {
      this.notificationsService.notificationsIsOpen();
    }
  }

  switchModule(module) {
    this.fohmoduleSelectorService.changeModule(module);
  }
}
