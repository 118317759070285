import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Reservation } from '@foh/reservations/reservation';
import { AlertService } from '@foh/shared/services/alert.service';
import { ReservationsService } from "@foh/reservations/reservations.service";

export interface ReservationUpdaterParams {
    params? : any;
    reservation? : Reservation;
    request? : Reservation;
    newCounter? : number;
    list? : any;
    userId? : number;
    type : string;
}

@Injectable({
  providedIn: 'root'
})
export class ReservationupdaterService {
    private subject = new Subject<ReservationUpdaterParams>();
    constructor(private alertService : AlertService,private reservationsService:ReservationsService) { }

    forceRefresh(){
        this.subject.next({ type: "reservations-force-refresh" });
    }

    add(reservation){
        this.subject.next({ type: "reservation-add", reservation: reservation });
    }

    addRequest(request){
        this.alertService.newRequest();
        this.subject.next({ type: "request-add", request: request });
    }

    requestApproved(reservation){
        this.subject.next({ type: "request-approved", reservation: reservation });
    }

    requestRejected(reservation){
        this.subject.next({ type: "request-rejected", reservation: reservation });
    }

    update(reservation,userId){
        this.subject.next({ type: "reservation-update", reservation: reservation , userId:userId});
    }
    updateWithTables(reservation,userId){
      //this.update(reservation,userId);
      this.subject.next({ type: "reservation-tables-update", reservation: reservation , userId:userId });
    }
    massDeallocate(params,userId){
      this.subject.next({ type: "mass-tables-deallocate", params: params , userId:userId });
    }

    updateGuestlist(){
      //This method is only called from reservations component
      //There is no params because it is inner called
        this.subject.next({ type: "reservation-guestlist-update"});
    }
    updateTicketlist(){
      //This method is only called from reservations component
      //There is no params because it is inner called
        this.subject.next({ type: "reservation-ticketlist-update"});
    }

    updateAttendance(reservation,list,userId){
      //this.subject.next({ type: "reservation-attendance-update" , reservation:reservation , list:list });
      //Save At Cache
      //I really dnt know why i am saving new attendance update at cache first then broadcasting it to reservation screen,
      //May be this is a better a approach, Cleaner Approach for handling server updates.
      //No, it is better to let the cardUpdated handle all cache updates.
      // var params = {
      //   date: reservation.date,
      //   branch_id: reservation.branch_id
      // };
      // if(reservation.by_slot){
      //   params.slot_id = reservation.slot_id;
      // }else{
      //   params.slot_id = -99;
      // }
      // this.reservationsService.updateReservationAtCache(params,reservation);
      //this.reservationsService.updateAttendanceOfListAtCache(params,list);
      this.subject.next({ type: "reservation-attendance-update", reservation:reservation , list:list,userId:userId});
    }

    broadcastIncomingCallReservation(){
        this.subject.next({ type: "incoming-reservation-from-call"});
    }

    updatePendingRequestsBadge(newCounter){
        this.subject.next({ type: "pendingrequests-counter-update",newCounter: newCounter });
    }

    onAttendanceOpen(reservation){
        this.subject.next({ type: "on-attendance-modal-open",reservation: reservation,params:{highlightCode:false} });
    }
    onAttendanceOpenWithCodeHighlight(reservation,code){
        this.subject.next({ type: "on-attendance-modal-open",reservation: reservation,params:{highlightCode:true,code:code} });
    }
    onWeddingDetailsOpen(reservation){
        this.subject.next({ type: "on-weddingdetails-modal-open",reservation: reservation });
    }
    onWeddingAddonsOpen(reservation){
        this.subject.next({ type: "on-weddingaddons-modal-open",reservation: reservation });
    }
    onCloseOpen(reservation){
        this.subject.next({ type: "on-close-modal-open",reservation: reservation });
    }
    onEditReservationOpen(reservation){
        this.subject.next({ type: "on-editreservation-modal-open",reservation: reservation });
    }
    onManagePassRequestOpen(reservation){
        this.subject.next({ type: "on-managepassrequest-modal-open",reservation: reservation });
    }
    onGuestlistOpen(reservation){
        this.subject.next({ type: "on-guestlist-modal-open",reservation: reservation });
    }
    onTicketlistOpen(reservation){
        this.subject.next({ type: "on-ticketlist-modal-open",reservation: reservation });
    }
    onManageDepositOpen(reservation){
        this.subject.next({ type: "on-managedeposit-modal-open",reservation: reservation });
    }
    onManagePaymentOpen(reservation){
        this.subject.next({ type: "on-managepayment-modal-open",reservation: reservation });
    }
    onManageRequestOpen(reservation){
        this.subject.next({ type: "on-managerequest-modal-open",reservation: reservation });
    }
    onManageTagsOpen(reservation){
        this.subject.next({ type: "on-tags-modal-open",reservation: reservation });
    }
    onManageQrInvOpen(reservation){
        this.subject.next({ type: "on-qrinv-modal-open",reservation: reservation });
    }
    onActivityLogOpen(reservation){
        this.subject.next({ type: "on-activitylog-modal-open",reservation: reservation });
    }
    onMessagesLogOpen(reservation){
        this.subject.next({ type: "on-messageslog-modal-open",reservation: reservation });
    }


    listen(){
        return this.subject.asObservable();
    }
}
