import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { AddCustomerComponent  } from './add-customer/add-customer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomFormsModule } from 'ng2-validation';
import { PickCallComponent } from './pick-call/pick-call.component';
import { CustomerFlagsComponent } from './customer-flags/customer-flags.component';
import { ChangeMobilenoComponent } from './change-mobileno/change-mobileno.component';
import { SearchCustomersComponent } from './search-customers/search-customers.component';
@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    RouterModule
  ],
  declarations: [AddCustomerComponent, PickCallComponent, CustomerFlagsComponent, ChangeMobilenoComponent, SearchCustomersComponent],
  entryComponents:[AddCustomerComponent,PickCallComponent,ChangeMobilenoComponent],
  exports : [AddCustomerComponent,PickCallComponent,CustomerFlagsComponent,ChangeMobilenoComponent,SearchCustomersComponent]
})
export class SharedCustomersComponentModule { }
