import { FormGroup, FormControl, ValidatorFn, FormArray } from "@angular/forms";

export function emailValidator(control: FormControl): { [key: string]: any } {
  let emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;//warning , fe copy mnha fe ManagerequestPassvOneComponent
  if (control.value && !emailRegexp.test(control.value.toLowerCase())) {
    return { invalidEmail: true };
  }
}

export function passwordValidator(
  control: FormControl
): { [key: string]: any } {
  var password = control.value;
  var flagForLength = password.length >= 6;
  var flagForUpper = /[A-Z]+/.test(password);
  var flagForDigit = /[0-9]+/.test(password);
  var flagForSpecial = /[!@#$%^&*()]+/.test(password);
  if (!flagForLength || !flagForUpper || !flagForDigit || !flagForSpecial) {
    return { invalidPassword: true };
  }
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
export function nameValidator(control: FormControl): { [key: string]: any } {
  // Write Logic to Validate that Incoming control value have no numbers
  if (control.value == null) {
    return { invalidName: false };
  }
  let matches = control.value.match(/\d+/g);

  if (matches != null) {
    return { invalidName: true };
  }
}
export function numberValidator(control: FormControl): { [key: string]: any } {
  // Write Logic to Validate that Incoming control value have no numbers
  if (!isNumeric(control.value)) {
    return { invalidNumber: true };
  }
}
export function phoneValidator(control: FormControl): { [key: string]: any } {
  // Write Logic to Validate that Incoming control value have no numbers
  if (!isNumeric(control.value)) {
    return { invalidPhone: true };
  }
}

export function matchingPasswords(
  passwordKey: string,
  passwordConfirmationKey: string
) {
  return (group: FormGroup) => {
    const password = group.controls[passwordKey];
    const passwordConfirmation = group.controls[passwordConfirmationKey];
    if (password.value !== passwordConfirmation.value) {
      return passwordConfirmation.setErrors({ mismatchedPasswords: true });
    }
  };
}

export function minSelectedCheckboxes(min = 1) {
  const validator: ValidatorFn = (formArray: FormArray) => {
    const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
      .map(control => control.value)
      // total up the number of checked checkboxes
      .reduce((prev, next) => (next ? prev + next : prev), 0);

    // if the total is not greater than the minimum, return the error message
    return totalSelected >= min ? null : { required: true };
  };
  return validator;
}
