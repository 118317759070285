import { Component, OnInit, OnDestroy,ViewChild,ElementRef } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  tap,
  switchMap,
  merge,
  filter
} from "rxjs/operators";
import { CustomersService } from "@foh/customers/customers.service";
@Component({
  selector: "app-search-customers",
  templateUrl: "./search-customers.component.html",
  styleUrls: ["./search-customers.component.scss"]
})
export class SearchCustomersComponent implements OnInit, OnDestroy {
  searching: boolean = false;
  searchFailed: boolean = false;
  public autoCompleteInput: any;
  @ViewChild('autoCompleteTextInput') autoCompleteTextInput:ElementRef;
  constructor(
    private customersService: CustomersService,
    private router: Router
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  searchCustomer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(value => (value.length < 4 ? false : true)),
      tap(() => (this.searching = true)),
      switchMap(term => this.searchDefaultCustomerMethod(term)),
      tap(() => (this.searching = false))
    );

  resultFormatter = x => {
    if (typeof x.id !== "undefined" && x.id === -1) {
      return x.name;
    }
    var name = x.fname + " " + x.lname + " - " + x.main_profile.mobileno;
    if (x.isblacklist) {
      name += " -BLACKLISTED- ";
    }
    if (x.rejectedreason_id != null && x.rejectedreason_id != 0 && x.rejectedreason_id != "" ) {
      name += " -REJECTED- ";
    }
    return name;
  };

  inputFormatter = x => {
    if (x.id === -1) {
      return "";
    }
    return x.fname + " " + x.lname;
  };

  selectCustomer(item) {
    var mainId = item.item.main_profile.id;
    this.router.navigate(["customers/view-profile/" + mainId]);
    setTimeout(() => {
      this.reset();
    }, 50);
  }

  searchDefaultCustomerMethod(term) {
    return this.customersService.search(term).pipe(
      tap(() => (this.searchFailed = false)),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })
    );
  }

  reset() {
    this.autoCompleteInput = "";
  }

  forceTriggerAutoComplete(){
    this.autoCompleteTextInput.nativeElement.dispatchEvent(new Event('input'));
    this.autoCompleteTextInput.nativeElement.focus();
  }
}
