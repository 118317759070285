import { Component, OnInit , EventEmitter, Input,Output,OnDestroy} from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-mobileno',
  templateUrl: './change-mobileno.component.html',
  styleUrls: ['./change-mobileno.component.scss']
})
export class ChangeMobilenoComponent implements OnInit,OnDestroy {
    @Input() loading: boolean;
    @Input() record: any;
    @Output() changeMobileNumber = new EventEmitter();
    newNumber : any = "";
  constructor(public activeModal: NgbActiveModal) 
  {
      this.record= {};
   }

  ngOnInit() {
  }
  
  ngOnDestroy(){
    this.changeMobileNumber.unsubscribe();
    this.changeMobileNumber = null;
  }
  
   
  save(){
      if(!this.validate()){
          return false;
      }
      this.changeMobileNumber.emit(this.newNumber);
       
  }
  
   validate(){
      return true;
  }
  
  

}
