import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { CustomerauthService } from './customerauth.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import * as alertFunctions from '../data/sweet-alerts';
import { Router, ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  

  constructor(public auth: AuthService,public customerauthService:CustomerauthService,private router:Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    // this is for set a specific token for Whatsapp API
    if (request.headers.has('X-Custom-Header')) {
      return next.handle(request);
    }

      if(typeof request.body !== "undefined" && request.body !== null && typeof request.body.injectTokenAsAuth !== "undefined"){
        if(request.body.fohTokenValue != null && request.body.fohTokenValue != ""){
          request = request.clone({
             setHeaders: {
                 Authorization: `Bearer ${request.body.fohTokenValue}`
             }
         });
        }
      }
      else if(this.customerauthService.isAuthenticated() ) {
        request = request.clone({
           setHeaders: {
               Authorization: `Bearer ${this.customerauthService.getToken()}`
           }
       });
      }else if( this.auth.getToken() !== null ){
             request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getToken()}`
                }
            });
      }



    return next
    .handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {

        // do stuff with response if you want
          //debugger;
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.error.error === "token_not_provided") {
          // redirect to the login route
          // or show a modal
            //debugger;
            if(err.error.error === "invalid_credentials"){
                alertFunctions.error("Invalid Credentials","Wrong username/password.");
            }
            else if(err.error.error === "user_is_suspended"){
                alertFunctions.error("User Is Inactive","User is not activated, Please contact your manager to reactivate it.");
            }
            else if(err.error.error === "mcompany_inactive"){
                alertFunctions.error("Management Company is inactive","Management Company is inactive, please contact FOH support to reactivate it.");
            }
            else{
                this.auth.logout();
                alertFunctions.unAuthError().then(()=>
                {

                    this.router.navigate(['login']);
                    location.reload();

                });
            }

//
        }
        //If Invalid Data
        if (err.status === 400) {
            if(err.error.error == "viewas_not_set"){
                this.auth.logout();
                this.router.navigate(['login']);
                location.reload();
                return;
            }
            if(err.error.error == "user_is_suspended"){
                this.auth.logout();
                this.router.navigate(['login']);
                location.reload();
                return;
            }
            if(err.error.error == "mcompany_inactive"){
                this.auth.logout();
                this.router.navigate(['login']);
                location.reload();
                return;
            }
            if(err.error.error == "license_inactive"){
                alertFunctions.error("License is expired","Your license has expired, Please contact FOH support team to renew it.");
                this.router.navigate(['subscriptions/details']);
                setTimeout(() => {
                    location.reload();
                }, 3000);
                return;
            }

            if(err.error.error == "customerprofile_found"){
                return;
            }
            var showGlobalAlert = true;
            if(typeof err.error.hideGlobalAlert !== "undefined" && err.error.hideGlobalAlert){
              showGlobalAlert = false;
            }
            if(showGlobalAlert){
              alertFunctions.error(err.error.title,err.error.message);
            }
        }
        if (err.status === 500) {
            alertFunctions.error("Error!",err.error.message);
        }
      }
    });
  }
}
