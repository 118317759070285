import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  ViewChild,
  Output,
  OnDestroy
} from "@angular/core";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
  NgbDateStruct,
  NgbDatepicker,
  NgbDatepickerI18n,
  NgbCalendar
} from "@ng-bootstrap/ng-bootstrap";
import { ClientCategory } from "@foh/mcompany-setup/client-cats/client-category";
import { LookupService } from "@foh/shared/services/lookup.service";
import * as utils from "@foh/shared/services/utils";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray
} from "@angular/forms";
import {
  emailValidator,
  phoneValidator,
  nameValidator
} from "@foh/shared/directives/app-validators";
import { FohmoduleSelectorService } from "@foh/shared/services/fohmodule-selector.service";

@Component({
  selector: "app-add-customer",
  templateUrl: "./add-customer.component.html",
  styleUrls: ["./add-customer.component.scss"]
})
export class AddCustomerComponent implements OnInit, OnDestroy {
  @Output()
  newCustomer = new EventEmitter<string>();

  @Output()
  updateCustomer = new EventEmitter<string>();

  @Input()
  term: any;

  @Input() loading: boolean;

  record: any;

  @Input()
  districts: any;

  @Input()
  publicProfile: boolean;

  @Input()
  clientCategories: ClientCategory[];

  @ViewChild("d2") birthdateDatePicker: NgbDatepicker;

  disableMobileNumberUpdate: boolean;
  disableEmailUpdate: boolean;
  rejectionList: any;

  noValue: boolean;
  yesValue: boolean;
  zeroValue: number;

  newBirthday: any;
  showCurrentBirthday: boolean;

  allowedActions: any;
  isGenderMandtory: boolean;
  isClientCategoryMandtory: boolean;
  selectedFile: File = null;
  form: FormGroup;
  formSubmitted = false;

  minimumBirthDate: any;
  maximumBirthDate: any;
  currentSelectedModule: string;
  isEventTypeRequired: any;

  constructor(public activeModal: NgbActiveModal,
    private lookupService: LookupService,
    private fohmoduleSelectorService: FohmoduleSelectorService,
    public formBuilder: FormBuilder) {
    this.disableMobileNumberUpdate = false;
    this.disableEmailUpdate = false;
    this.record = {};
    this.noValue = false;
    this.yesValue = true;
    this.zeroValue = 0;
    this.newBirthday = null;
    this.showCurrentBirthday = true;
    this.managePermissions();
  }

  ngOnChange() {
  }

  ngAfterViewInit(): void {
    // Access the birthdateDatePicker here
    if (!this.publicProfile) {
      var birthMinDate = new Date();
      birthMinDate.setFullYear(birthMinDate.getFullYear() - 100);
      var birthMaxDate = new Date();
      birthMaxDate.setFullYear(birthMaxDate.getFullYear() - 21);
      this.minimumBirthDate = {
        year: birthMinDate.getFullYear(),
        month: birthMinDate.getMonth() + 1,
        day: birthMinDate.getDate()
      };
      this.maximumBirthDate = {
        year: birthMaxDate.getFullYear(),
        month: birthMaxDate.getMonth() + 1,
        day: birthMaxDate.getDate()
      };
      this.birthdateDatePicker.minDate = this.minimumBirthDate;
      this.birthdateDatePicker.maxDate = this.maximumBirthDate;
    }

  }

  ngOnInit() {
    this.currentSelectedModule = this.fohmoduleSelectorService.getCurrentModule();
    if (typeof this.record.id === "undefined") {
      this.record.fname = "";
      this.record.lname = "";
      this.record.pname = "";
      this.record.mobileno = "";
    }
    this.isGenderMandtory = this.lookupService.getGlobalConfig()['mandtorygenderoncreate'];
    this.manageClientCategoryMandatoryStatus();
    this.rejectionList = this.lookupService.getAllRejectionList();
    if (this.term != "") this.detectTerm();
    this.createForm();
    if (typeof this.record.id !== "undefined") {
      this.populateForm();
    }
    this.isEventTypeRequired = this.lookupService.getGlobalConfig()['customerprofileeventtypeismandatory']

  }

  ngOnDestroy() {
    this.term = null;
    this.record = null;
    this.districts = null;
    this.clientCategories = null;
    this.newCustomer.unsubscribe();
    this.newCustomer = null;
    this.updateCustomer.unsubscribe();
    this.updateCustomer = null;
  }

  manageClientCategoryMandatoryStatus() {
    if (!this.allowedActions.canChangeCC) {
      this.isClientCategoryMandtory = false;
      return;
    }
    this.isClientCategoryMandtory = this.lookupService.getGlobalConfig()['mandtorycategoryoncreate'] && this.clientCategories.length > 0;
  }

  managePermissions() {
    this.allowedActions = {};
    this.allowedActions.canChangeCC = true;
    this.allowedActions.canChangeBlacklist = true;
    this.allowedActions.canChangeRejectionlist = true;
  }

  get f() {
    return this.form.controls;
  }


  createForm() {
    this.form = this.formBuilder.group({
      fname: [
        this.record.fname,
        Validators.compose([Validators.required, Validators.minLength(2), nameValidator])
      ],
      lname: [
        this.record.lname,
        Validators.compose([Validators.required, Validators.minLength(3), nameValidator])
      ],
      pname: [
        this.record.pname,
        Validators.compose([Validators.minLength(3), nameValidator])
      ],
      mobileno: [
        this.record.mobileno,
        Validators.compose([Validators.required, phoneValidator])
      ],
      clientcat_id: [
        "",
        Validators.compose([])
      ],
      event_type_id: [
        this.record.event_type_id,
        Validators.compose([])
      ],
      company: [
        "",
        Validators.compose([])
      ],
      position: [
        "",
        Validators.compose([])
      ],
      gender: [
        "",
        Validators.compose([])
      ],
      public_gender: [
        "",
        Validators.compose([])
      ],
      newBirthday: [
        null,
        Validators.compose([])
      ],
      isblacklist: [
        false,
        Validators.compose([])
      ],
      blacklist_reason: [
        "",
        Validators.compose([])
      ],
      rejectedreason_id: [
        "0",
        Validators.compose([])
      ],
      rejectedreason_details: [
        "",
        Validators.compose([])
      ],
      email: [
        "",
        Validators.compose([emailValidator])
      ],
      membership_code: [
        "",
        Validators.compose([])
      ],
      note: [
        "",
        Validators.compose([])
      ],
      districts_id: [
        "",
        Validators.compose([])
      ],
      address: [
        "",
        Validators.compose([])
      ],
      numberofchildren: [
        "",
        Validators.compose([])
      ],
      fblink: [
        "",
        Validators.compose([])
      ],
      public_fblink: [
        "",
        Validators.compose([])
      ],
      id: ["", Validators.compose([])],
    });


    //If Gender is required
    if (this.isGenderMandtory) {
      this.form.controls['gender'].setValidators([Validators.required]);
      this.form.controls['gender'].updateValueAndValidity();
    }
    //If CC is required
    if (this.isClientCategoryMandtory) {
      this.form.controls['clientcat_id'].setValidators([Validators.required]);
      this.form.controls['clientcat_id'].updateValueAndValidity();
    }

    if (!this.allowedActions.canChangeCC) {
      this.form.get('clientcat_id').disable({ emitEvent: false });
    }

    if (!this.allowedActions.canChangeBlacklist) {
      this.form.get('isblacklist').disable({ emitEvent: false });
      this.form.get('blacklist_reason').disable({ emitEvent: false });
    }
    if (!this.allowedActions.canChangeRejectionlist) {
      this.form.get('rejectedreason_id').disable({ emitEvent: false });
      this.form.get('rejectedreason_details').disable({ emitEvent: false });
    }

  }

  populateForm() {

    if (this.record.clientcat_id == null) {
      this.record.clientcat_id = "";
    }

    var pname = "";
    if (this.record.pname != null) {
      pname = this.record.pname;
    }

    this.form.patchValue({
      id: this.record.id,
      fname: this.record.fname,
      lname: this.record.lname,
      pname: pname,
      mobileno: this.record.mobileno,
      clientcat_id: this.record.clientcat_id,
      company: this.record.company,
      position: this.record.position,
      gender: this.record.gender,
      public_gender: this.record.public_gender,
      isblacklist: this.record.isblacklist,
      blacklist_reason: this.record.blacklist_reason,
      rejectedreason_id: this.record.rejectedreason_id,
      rejectedreason_details: this.record.rejectedreason_details,
      email: this.record.email,
      note: this.record.note,
      districts_id: this.record.districts_id,
      address: this.record.address,
      numberofchildren: this.record.numberofchildren,
      membership_code: this.record.membership_code,
      fblink: this.record.fblink,
      public_fblink: this.record.public_fblink,
      event_type_id: this.record.event_type_id
    });

    if (this.record.birthdate != null && this.record.birthdate != '0001-01-01') {
      this.form.patchValue({
        newBirthday: utils.formatDateSqlToCustomObject(this.record.birthdate)
      });
    }

    //If email is disabled
    if (this.disableEmailUpdate) {
      this.form.get('email').disable({ emitEvent: false });
    }
    if (this.disableMobileNumberUpdate) {
      this.form.get('mobileno').disable({ emitEvent: false });
    }
  }

  detectTerm() {
    if ($.isNumeric(this.term)) {
      this.record.mobileno = this.term; //Detected As Mobile Number
    } else {
      var firstName = this.term.substr(0, this.term.indexOf(" "));
      var lastName = this.term.substr(this.term.indexOf(" ") + 1);
      if (firstName == "" || lastName == "") {
        this.record.fname = this.term;
      } else if (firstName != "" && lastName != "") {
        this.record.fname = firstName;
        this.record.lname = lastName;
      }
    }
  }

  save() {
    this.formSubmitted = true;
    if (!this.form.valid) {
      return;
    }
    if (this.newBirthday !== null) {
      this.showCurrentBirthday = false;
      this.record.birthdate = this.newBirthday;
    }

    if (typeof this.record.id === "undefined") {
      this.newCustomer.emit(this.prepareRecord());
    } else {
      this.updateCustomer.emit(this.prepareRecord());
    }
  }

  prepareRecord() {
    var formData = utils.convertObjectToForm(this.form.value);
    if (this.selectedFile) {
      formData.append("image", this.selectedFile);
    }
    return formData;
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }


}
