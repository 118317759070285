import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Reservation } from '@foh/reservations/reservation';

export interface SmsParams {
    newBalance? : number;
    type : string;
}
 
@Injectable({
  providedIn: 'root'
})
export class SmsService {
    private subject = new Subject<SmsParams>();
    constructor() { }
    
    updateBalance(newBalance){
        this.subject.next({ type: "sms-balance-updated", newBalance: newBalance });
    }
    
    listen(){
        return this.subject.asObservable();
    }
}
