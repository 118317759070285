import { Injectable } from "@angular/core";
import { AlertService } from "./alert.service";
import { Subject } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";

export interface OperationEvent {
  operationId: any;
  type: string;
}

@Injectable({
  providedIn: "root"
})
export class OperationsupdaterService {
  private subject = new Subject<OperationEvent>();
  constructor(private alertService: AlertService,private router: Router) {}

  listen() {
    return this.subject.asObservable();
  }

  operationIsInProgress(operationId) {
    this.subject.next({
      type: "operation-in-progress",
      operationId: operationId
    });
  }
  operationIsCompleted(operationId) {
    this.subject.next({
      type: "operation-completed",
      operationId: operationId
    });

    this.alertService.clearAll();
    this.alertService
      .taskCompleted(
        "A Recent Operation is completed, Click here for more info."
      )
      .onTap.subscribe(response => {
        this.router.navigate(["reports/log"]);
      });
  }
}
