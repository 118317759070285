import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  constructor() { }

  playNewNotificationSound(){
    var sound = "/assets/sounds/notification.mp3";
    var audio = new Audio(sound);
    audio.play();
  }
  playNewCallSound(){
    var sound = "/assets/sounds/callnotification.mp3";
    var audio = new Audio(sound);
    audio.play();
  }

  playQRValid(){
    var sound = "/assets/sounds/qr_valid.wav";
    var audio = new Audio(sound);
    audio.play();
  }
  playQRNotValid(){
    var sound = "/assets/sounds/qr_error.wav";
    var audio = new Audio(sound);
    audio.play();
  }
}
