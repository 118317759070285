import { Component, OnInit , EventEmitter, Input,Output,OnDestroy} from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal ,NgbDateStruct, NgbDatepickerI18n, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { LookupService } from '@foh/shared/services/lookup.service';
import * as utils from '@foh/shared/services/utils';
import { ClientCategory  } from '@foh/mcompany-setup/client-cats/client-category';
@Component({
  selector: 'app-pick-call',
  templateUrl: './pick-call.component.html',
  styleUrls: ['./pick-call.component.scss']
})
export class PickCallComponent implements OnInit {

    record : any;
    venues:any;
    branches:any;
    districts: any;
    clientCategories: ClientCategory[];
    filteredSlots : any = [];

    @Input() customerProfile :any;
    @Input() mobileno :string;
    @Output()    proceedEmitter = new EventEmitter();

    constructor(public lookupService:LookupService,public activeModal: NgbActiveModal) { }

  ngOnInit() {
      this.clientCategories = this.lookupService.getClientCategories();
        this.districts = this.lookupService.getDistricts();
        var venueLength = this.lookupService.getNumberOfVenues();
        this.venues = this.lookupService.getVenues();

        this.record = {};
        this.record.venue_id = this.venues[0].id;
         var now = new Date();
         this.record.date = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
         this.updateBranches();
  }

  updateBranches(){
      this.branches = this.lookupService.getAllBranchesOfVenue(this.record.venue_id);
      this.record.branch_id = this.branches[0].id;
      this.filterSlots(this.record.date);
  }

   filterSlots(date){
       var slots = this.lookupService.getSlots(this.record.branch_id);
      this.filteredSlots = utils.filterSlotsPerDate(date,slots,false);
      this.record.slot = this.filteredSlots[0];
  }

   onDateChange(newDate){
      this.filterSlots(newDate);
  }

  onBranchChange(){
      this.filterSlots(this.record.date);
  }

  onSubmit(){
      this.proceedEmitter.emit(this.record);

  }



}
