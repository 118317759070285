import { Component, OnInit,Input,OnChanges } from '@angular/core';
import { LookupService } from '@foh/shared/services/lookup.service';
import * as utils from '@foh/shared/services/utils';

@Component({
  selector: 'app-customer-flags',
  templateUrl: './customer-flags.component.html',
  styleUrls: ['./customer-flags.component.scss']
})
export class CustomerFlagsComponent implements OnInit,OnChanges {

    @Input("customerprofile") customerProfile :any;


    clientcatCss : string= "not-active";
    clientcatTitle : string= "Not Set";
    freqCss : string= "not-active";
    freqTitle : string= "Frequent - Not Active";
    spenCss : string= "not-active";
    spenTitle : string= "Spender - Not Active";
    noshowCss : string= "not-active";
    noshowTitle : string= "No Show - Not Active";

  constructor(private lookupService:LookupService) { this.customerProfile = null; }

  ngOnInit() {
      this.refreshFlags();
  }
  ngOnChanges() {
      this.refreshFlags();
  }



  refreshFlags(){
      this.resetFlags();
      if(typeof this.customerProfile === "undefined" || this.customerProfile == null ){
          return;
      }
      var clientCategory = utils.getClientCategory(this.lookupService,this.customerProfile);
      if(clientCategory != null){
          this.clientcatCss = clientCategory.cssclass;
          this.clientcatTitle =clientCategory.name;
      }
       if(this.customerProfile.frequent == true){
            this.freqCss = "active";
            this.freqTitle = "Frequent - High Frequent";
        }
        if(this.customerProfile.spender == 1){
            this.spenCss = "low";
            this.spenTitle = "Low Spender";
        }
        if(this.customerProfile.spender == 2){
            this.spenCss = "medium";
            this.spenTitle = "Medium Spender";
        }
        if(this.customerProfile.spender == 3){
            this.spenCss = "high";
            this.spenTitle = "High Spender";
        }
        if(this.customerProfile.noshow == 1){
            this.noshowCss = "noshow";
            this.noshowTitle = "No Show - Active";
        }
  }
  resetFlags(){
    this.clientcatCss = "not-active";
    this.freqCss = "not-active";
    this.spenCss = "not-active";
    this.noshowCss = "not-active";
    this.clientcatTitle = "Not Set";
    this.freqTitle = "Frequent - Not Active";
    this.spenTitle = "Spender - Not Active";
    this.noshowTitle = "No Show - Not Active";
  }

}
