import { Routes, RouterModule } from "@angular/router";
import { GuestGuard } from "@foh/shared/auth/guest-guard.service";
//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
  {
    path: "",
    loadChildren: "./publicpages/publicpages.module#PublicpagesModule"
  },
  {
    path: "login",
    loadChildren: "./login/login.module#LoginModule",
    canActivate: [GuestGuard]
  },
  {
    path: "pass/registration",
    loadChildren: "./pass/pass.module#PassModule"
  },
  {
    path: 'rez/reservation',
    loadChildren: './rez/rez.module#RezModule'
  },
];
