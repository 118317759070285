import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { Observable, Subject } from 'rxjs';
import { AudioService } from '@foh/shared/services/audio.service';

export interface CallEventParams {
    params? : any;
    type : string;
}

@Injectable({
  providedIn: 'root'
})
export class CallmanagerService {
    private subject = new Subject<CallEventParams>();
    autoPopulateReservationFlag :boolean= false;
    constructor(private alertService:AlertService,private audioService:AudioService) { }


  currentCall :any;

  notifyForNewCall(params :any){
      var message = "";
      if(params.customerprofile == null ||  params.customerprofile.id == -1 ){
          message+="An Unknown Number ( "+params.mobileno+" ) is calling, ";
          if(params.customerprofile == null){
            params.customerprofile = {id:-1};
          }
      }else{
          message+="'"+params.customerprofile.fname+" "+params.customerprofile.lname+"' is calling, ";
      }
      message+="Click here to pickup call";
      this.alertService.clearAll();
      this.audioService.playNewCallSound();
      this.alertService.newCall(message).onTap.subscribe( (response)=>
      {
          this.newCall(params);
          this.openStep1Modal();
      } );
  }

  openStep1Modal(){
        this.subject.next({ type: "open-step1-modal", params: this.currentCall });
  }

  newCall(params :any){
      this.currentCall = {};
      this.currentCall.customerprofile = params.customerprofile;
      this.currentCall.mobileno = params.mobileno;
      this.currentCall.step = 1;
  }

  saveCallParams(params :any){
      this.currentCall.date = params.date;
      this.currentCall.branchId = params.branch_id;
      this.currentCall.slot= params.slot;
      this.currentCall.step = 2;
      this.autoPopulateReservationFlag = true;

  }

  updateCustomer(params:any){
      this.currentCall.customerprofile = params.customer;
  }

  resetCall(){
       this.currentCall = {};
       this.autoPopulateReservationFlag = false;
  }

  listen(){
        return this.subject.asObservable();
    }

}
