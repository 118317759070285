import { Injectable } from "@angular/core";
import { Globals } from "@foh/globals";
import { createNewDateFromString } from "./utils";
@Injectable({
  providedIn: "root"
})
export class CachemanagerService {
  TYPE_RESERVATIONS = 1;
  TYPE_LOOKUPS = 2;
  TYPE_GUESTSLIST = 3;
  TYPE_LAYOUT = 5;
  TYPE_STATS = 6;
  TYPE_TABLES = 7;
  TYPE_METADATA = 8;
  DIC_KEY = "";
  LASTCLEAN_KEY = "";
  constructor(private globals: Globals) {
    this.DIC_KEY = this.globals.STORAGE_PREFIX + ":keydic";
    this.LASTCLEAN_KEY = this.globals.STORAGE_PREFIX + ":lastclean";
    this.autoCleanOldKeys();
  }

  save(type, params, data) {
    var stringKey = this.getStringKey(type, params);
    var stringData = null;
    var state = true;

    switch (type) {
      case this.TYPE_RESERVATIONS:
        stringData = this.prepareDataForReservations(data);
        break;
      case this.TYPE_LAYOUT:
        stringData = this.prepareDataForReservations(data);
        break;
      case this.TYPE_STATS:
        stringData = this.prepareDataForReservations(data);
        break;
      case this.TYPE_TABLES:
        stringData = this.prepareDataForReservations(data);
        break;
      case this.TYPE_GUESTSLIST:
        stringData = this.prepareDataForReservations(data);
        break;
      case this.TYPE_METADATA:
        stringData = this.prepareDataForReservations(data);
        break;
      case this.TYPE_LOOKUPS:
        break;
    }
    this.addKeyToDic(stringKey, params);
    localStorage.setItem(stringKey, stringData);
  }

  retrive(type, params) {
    var stringKey = this.getStringKey(type, params);
    var data = null;
    var parsedData = null;
    data = localStorage.getItem(stringKey);
    parsedData = JSON.parse(data);
    return parsedData;
  }

  removeByKey(stringKey) {
    localStorage.removeItem(stringKey);
    this.removeFromDic(stringKey);
  }

  getStringKey(type, originalParams) {
    var params = { ...originalParams };    
    if (typeof params.mode === "undefined") {
      params.mode = "hourbased";
      params.slot_id = -99;
    }
    if (typeof params.slot_id === "undefined") {
      params.slot_id = -99;
    }
    var stringKey = null;
    switch (type) {
      case this.TYPE_RESERVATIONS:
        stringKey = this.prepareKeyForReservations(params);
        break;
      case this.TYPE_LAYOUT:
        stringKey = this.prepareKeyForLayout(params);
        break;
      case this.TYPE_STATS:
        stringKey = this.prepareKeyForStats(params);
        break;
      case this.TYPE_TABLES:
        stringKey = this.prepareKeyForTables(params);
        break;
      case this.TYPE_GUESTSLIST:
        stringKey = this.prepareKeyForGuestslist(params);
        break;
      case this.TYPE_METADATA:
        stringKey = this.prepareKeyForMetaData(params);
        break;
      case this.TYPE_LOOKUPS:
        break;
    }
    return stringKey;
  }

  prepareDataForReservations(data) {
    var savedData: any = {};
    savedData.data = data;
    savedData.timestamp = new Date();
    return JSON.stringify(savedData);
  }

  prepareKeyForReservations(params) {
    var keyString = this.globals.STORAGE_PREFIX + ":";
    keyString += "r_";
    keyString += "branch_" + params.branch_id;
    keyString += this._addModeToKeyString(params);
    keyString += "date_" + params.date;
    if(typeof params.dateto !== "undefined"){
      keyString += "dateto_" + params.dateto;
    }
    if(typeof params.scope !== "undefined"){
      keyString += "scope_" + params.scope;
    }
    return keyString;
  }
  prepareKeyForLayout(params) {
    var keyString = this.globals.STORAGE_PREFIX + ":";
    keyString += "ly_";
    keyString += "branch_" + params.branch_id;
    keyString += this._addModeToKeyString(params);
    keyString += "date_" + params.date;
    return keyString;
  }
  prepareKeyForStats(params) {
    var keyString = this.globals.STORAGE_PREFIX + ":";
    keyString += "st_";
    keyString += "branch_" + params.branch_id;
    keyString += this._addModeToKeyString(params);
    keyString += "date_" + params.date;
    return keyString;
  }
  prepareKeyForTables(params) {
    var keyString = this.globals.STORAGE_PREFIX + ":";
    keyString += "tb_";
    keyString += "branch_" + params.branch_id;
    keyString += this._addModeToKeyString(params);
    keyString += "date_" + params.date;
    return keyString;
  }

  prepareKeyForGuestslist(params) {
    var keyString = this.globals.STORAGE_PREFIX + ":";
    keyString += "gl_";
    keyString += "branch_" + params.branch_id;
    keyString += this._addModeToKeyString(params);
    keyString += "date_" + params.date;
    return keyString;
  }

  prepareKeyForMetaData(params) {
    var keyString = this.globals.STORAGE_PREFIX + ":";
    keyString += "meta_";
    keyString += "branch_" + params.branch_id;
    keyString += this._addModeToKeyString(params);
    keyString += "date_" + params.date;
    return keyString;
  }

  _addModeToKeyString(params){
    var string = "";
    if(params.mode == "eventbased"){
      string += "event_" + params.event_id;
    }else{
      string += "slot_" + params.slot_id;
    }
    return string;
  }

  addKeyToDic(key, params) {
    var value = localStorage.getItem(this.DIC_KEY);
    var data = [];
    if (value != null) {
      data = JSON.parse(value);
    }
    var keyIndex = this.indexOfKeyAtDic(data, key);
    if (keyIndex === -1) {
      data.push({ key: key, date: params.date });
    }
    var dataJson = JSON.stringify(data);
    localStorage.setItem(this.DIC_KEY, dataJson);
  }

  removeFromDic(key) {
    var value = localStorage.getItem(this.DIC_KEY);
    var data = [];
    if (value != null) {
      data = JSON.parse(value);
    }
    var keyIndex = this.indexOfKeyAtDic(data, key);
    if (keyIndex !== -1) {
      data.splice(keyIndex, 1);
    }
    var dataJson = JSON.stringify(data);
    localStorage.setItem(this.DIC_KEY, dataJson);
  }

  indexOfKeyAtDic(data, key) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].key == key) {
        return i;
      }
    }
    return -1;
  }

  autoCleanOldKeys() {
    var value = localStorage.getItem(this.LASTCLEAN_KEY);
    var shouldCleanCheck = this._shouldAutoClean(value);
    var allDics = [];
    if (shouldCleanCheck) {
      var todayDate = new Date();
      var allDicsString = localStorage.getItem(this.DIC_KEY);
      if (allDicsString != null) {
        allDics = JSON.parse(allDicsString);
      }
      for (var i = 0; i < allDics.length; i++) {
        var key = allDics[i].key;
        var dicDate = createNewDateFromString(allDics[i].date);
        var differenceInDays = this._calculateDifferenceInDays(
          dicDate,
          todayDate
        );
        if (differenceInDays > 1) {
          this.removeByKey(key);
        }
      }
      var dateJson = todayDate.toJSON();
      var dateString = JSON.stringify(dateJson);
      localStorage.setItem(this.LASTCLEAN_KEY, dateString);
    }
  }

  clearLayoutCacheByLayoutId(layoutId) {
    var keyString = this.globals.STORAGE_PREFIX + ":ly_";
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key.startsWith(keyString)) {
        continue;
      }
      var layout = JSON.parse(localStorage.getItem(key));
      if (layout.data.id == layoutId) {
        this.removeByKey(key);
      }
    }
  }

  _shouldAutoClean(lastCleanDate) {
    var shouldClean = false;
    if (lastCleanDate === null) {
      shouldClean = true;
    } else {
      //Compare Between last Clean Time
      var date = new Date(JSON.parse(lastCleanDate));
      var currentDate = new Date();
      var differenceInDays = this._calculateDifferenceInDays(date, currentDate);
      if (differenceInDays > 1) {
        shouldClean = true;
      }
    }
    return shouldClean;
  }

  _calculateDifferenceInDays(firstDate, secondDate) {
    const diffTime = Math.abs(secondDate.getTime() - firstDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
}
