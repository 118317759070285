import { RouteInfo } from './sidebar.metadata';
import { environment } from "environments/environment";

export const WEDDINGGROUTES: RouteInfo[] = [
  {
    path: '/' + environment.KEYWORD_CITADEL + '/operations', title: 'Bookings', icon: 'fa fa-list', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
  {
    path: '/' + environment.KEYWORD_CITADEL + '/pending-requests', title: 'Pending Requests', icon: 'foh-icon flag-noshow', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
  {
    alias: "customers", path: '/customers/list', title: 'Profiles', icon: 'foh-icon foh-customers', class: '', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_reservationcustomersmanage", "permission_reservationcustomersview"], submenu: []
  },
  {
    path: '', title: 'Settings', icon: 'foh-icon foh-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_setup_mcompany"],
    submenu: [
      { path: '/' + environment.KEYWORD_CITADEL + '/general-config', title: 'General Config', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/' + environment.KEYWORD_CITADEL + '/event-types/list', title: 'Event Types', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/' + environment.KEYWORD_CITADEL + '/branches/list', title: 'Branches', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/' + environment.KEYWORD_CITADEL + '/add-ons/list', title: 'Add Ons', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/' + environment.KEYWORD_CITADEL + '/slots/list', title: 'Slots', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/templates/sms', title: 'SMS Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/templates/email', title: 'Email Templates', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/mcompany-setup/terms', title: 'Static Pages', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }


    ]
  },
  {
    path: '', title: 'Users Setup', icon: 'foh-icon foh-customers', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, permissions: ["permission_usermanagement"],
    submenu: [
      { path: '/users-setup/superadmins', title: 'SuperAdmins', icon: '', class: '', badge: 'SA', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isSuperAdmin: true, isExternalLink: false, submenu: [] },
      { path: '/users-setup/users', title: 'Users List', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/users-setup/users/logs', title: "Users' Activity Log", icon: '', class: '', badge: '', badgeClass: '', isSuperAdmin: true, isExternalLink: false, submenu: [] },
      { path: '/users-setup/roles', title: 'Roles', icon: '', class: '', badge: '', badgeClass: '', isSuperAdmin: true, isExternalLink: false, submenu: [], permissions: ["permission_usermanagement_roles"] },
    ]
  },
];
